import { useEffect, useState } from 'react';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AssessmentDefinitionResponse } from '../type';

interface useGetAssessmentDefinitionsResponse {
    data: AssessmentDefinitionResponse[];
    loading: boolean;
    error: unknown;
}

interface UseGetAssessmentDefinitionsProps {
    hasAssessments?: boolean; // Optional filter parameter for Dashboard to only return Definitions that have valid Assessment Statuses and Responses
    currentVersions?: boolean; // Optional filter parameter to only return Definitions that are NOT marked as deprecated
}

const useGetAssessmentDefinitions = ({ hasAssessments = false, currentVersions = false }: UseGetAssessmentDefinitionsProps = {}): useGetAssessmentDefinitionsResponse => {
    const [data, setData] = useState<AssessmentDefinitionResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const params = {
                    ...(hasAssessments && { has_assessments: true }),
                    ...(currentVersions && { current_versions: true }),
                };
                const response = await axiosV2Instance.get<AssessmentDefinitionResponse[]>(
                    EndPoints.ASSESSMENT_DEFINITIONS,
                    { params }
                );
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Runs once on component mount

    return { data, loading, error };
};

export default useGetAssessmentDefinitions;