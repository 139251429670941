import React, { useEffect, useRef, useState } from 'react';
import { CustomAccountBranding } from '../../Branding/type';
import { LoginResponse } from '../../Auth/reducers/authenticateUser';
import { DashboardComponent, DashboardConfigurationItemComponent } from '../type';
import GridStack from './GridStack';
import useGetDashboardConfiguration from '../hooks/useGetDashboardConfigurations';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Container, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { RootStoreType } from '../../../redux/store.type';
import { DaniaButton } from '../../Accounts/CreateClient/CreateClient.styles';
import useDeleteDashboardConfiguration from '../hooks/useDeleteDashboardConfiguration';
import { resetDashboardConfigurationState } from '../reducers/getDashboardConfiguration';
import { useAppDispatch } from '../../../redux/store';
import useGetAssessmentDefinitions from '../../Assessments/hooks/useGetAssessmentDefinitions';
import { AssessmentDefinitionResponse } from '../../Assessments/type';
import { useNavigate } from 'react-router-dom';
import { ComponentDataFunctionMap } from './components/DynamicComponentMapping/ComponentDataFunctionMap';
import useGetAssessmentDefinitionResilienceScore from '../hooks/useGetAssessmentDefinitionResilienceScore';
import { clearAssessmentDefinitionResilienceScore } from '../reducers/getAssessmentDefinitionResilienceScore';
import isEqual from 'lodash/isEqual';
import useGetAssessmentDefinitionOfferedSolutions from '../hooks/useGetAssessmentDefinitionOfferedSolutions';
import useGetAssessmentDefinitionCategoryScores from '../hooks/useGetAssessmentDefinitionCategoryScores';
import useGetAssessmentDefinitionQuestionScores from '../hooks/useGetAssessmentDefinitionQuestionScores';
import useGetAssessmentQuestionResponseDetails from '../../Assessments/hooks/useGetAssessmentQuestionResponseDetails';


interface AccountExecutiveDashboardProps {
  branding: CustomAccountBranding;
  loggedInUser: LoginResponse;
}

const AccountExecutiveDashboard: React.FC<AccountExecutiveDashboardProps> = ({ branding, loggedInUser }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getResilienceScore } = useGetAssessmentDefinitionResilienceScore();
  const { getOfferedSolutions } = useGetAssessmentDefinitionOfferedSolutions();
  const { getCategoryScores } = useGetAssessmentDefinitionCategoryScores();
  const { getQuestionScores } = useGetAssessmentDefinitionQuestionScores();
  const { getQuestionResponseDetails } = useGetAssessmentQuestionResponseDetails();

  const { getDashboardComponents, dashboardConfigurationLoading } = useGetDashboardConfiguration();
  const { deleteDashboardConfiguration } = useDeleteDashboardConfiguration();
  const { data: assessmentDefinitions } = useGetAssessmentDefinitions({ hasAssessments: true, currentVersions: true });

  const prevAssessmentRef = useRef<AssessmentDefinitionResponse | null>(null);
  const prevDashboardConfigRef = useRef<DashboardConfigurationItemComponent[] | null>(null);


  const [selectedAssessmentDefinition, setselectedAssessmentDefinition] = useState<AssessmentDefinitionResponse | null>(null);
  const [dashboardConfigCopy, setDashboardConfigCopy] = useState<DashboardConfigurationItemComponent[] | null>(null);
  const [dataFetching, setDataFetching] = useState(false);
  const isInitialRender = useRef(true);

  const selectDashboardConfiguration = createSelector(
    (state: RootStoreType) => state.dashboardReducers.getDashboardConfigurationReducer.dashboardConfiguration as (DashboardConfigurationItemComponent[] | null),
    (dashboardConfiguration) => dashboardConfiguration
  );

  const dashboardConfiguration = useSelector(selectDashboardConfiguration);

  async function handleDelete() {
    try {
      await deleteDashboardConfiguration(selectedAssessmentDefinition?.uuid as string);
      dispatch(resetDashboardConfigurationState());
      await getDashboardComponents();
    } catch (error) {
      console.error("Error handling dashboard deletion: ", error);
    }
  }

  const handleAssessmentChange = (event: SelectChangeEvent<number>) => {
    const selectedId = Number(event.target.value); // Extract selected id
    const selected = assessmentDefinitions.find((item) => item.id === selectedId); // Find the selected object
    setselectedAssessmentDefinition(selected || null); // Update state
  };

  useEffect(() => {
    if (isInitialRender.current && assessmentDefinitions.length > 0) {
      // Set the initial default assessment only once
      if (!selectedAssessmentDefinition) {
        setselectedAssessmentDefinition(assessmentDefinitions[0]);
        setDataFetching(true);
      }
      isInitialRender.current = false;
    }
  }, [assessmentDefinitions]);

  // Sync local state when Redux state changes
  useEffect(() => {
    if (dashboardConfiguration) {
      setDashboardConfigCopy([...dashboardConfiguration]); // Create a local copy
    }
  }, [dashboardConfiguration]);

  useEffect(() => {
    getDashboardComponents();
  }, [getDashboardComponents]);

  useEffect(() => {
    // Extract current values
    const prevAssessment = prevAssessmentRef.current;
    const prevDashboardConfig = prevDashboardConfigRef.current;

    // Check if values have changed
    const hasAssessmentChanged = prevAssessment?.id !== selectedAssessmentDefinition?.id;
    const hasDashboardConfigChanged = prevDashboardConfig !== null && !isEqual(prevDashboardConfig, dashboardConfiguration);

    // Only fetch data if values have changed AND both are non-null
    if ((hasAssessmentChanged || hasDashboardConfigChanged) && selectedAssessmentDefinition && dashboardConfiguration) {
      setDataFetching(true);

      // Fetch data for all components
      const fetchPromises = dashboardConfiguration
        .filter(item => item.assessment_definition_uuid === selectedAssessmentDefinition.uuid)
        .map((item) => {
          const fetchDataFunction = ComponentDataFunctionMap[item.component.key];
          return fetchDataFunction ? fetchDataFunction(selectedAssessmentDefinition, dispatch, item.component.question, getResilienceScore, getOfferedSolutions, getCategoryScores, getQuestionScores, getQuestionResponseDetails) : Promise.resolve();
        });

      // Wait for all data fetches to complete
      Promise.all(fetchPromises).then(() => {
        setDataFetching(false);
      });

      // Update refs ONLY if new valid data is available
      if (selectedAssessmentDefinition) prevAssessmentRef.current = selectedAssessmentDefinition;
      if (dashboardConfiguration) prevDashboardConfigRef.current = dashboardConfiguration;
    }
  }, [dashboardConfiguration, selectedAssessmentDefinition]);

  useEffect(() => {
    if (selectedAssessmentDefinition) {
      dispatch(clearAssessmentDefinitionResilienceScore());
    }
  }, [selectedAssessmentDefinition])

  if (dashboardConfigurationLoading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontSize: '24px',
          color: '#233264',
          fontWeight: 600,
          marginLeft: 1,
          marginBottom: 2
        }}>
          Hello {loggedInUser?.first_name},
        </Typography>
      </Grid>
      <Grid item md={12} sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        marginX: '10px',
      }}>
        <Grid item md={5}>
          <Select
            value={selectedAssessmentDefinition?.id || ''}
            onChange={handleAssessmentChange}
            displayEmpty
            variant="outlined"
            sx={{
              width: '100%',
              height: '34px',
              font: 'Inter',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '27px',
              paddingY: '12px',
              color: '#7F849B',
              borderRadius: '4px', // Adjust border radius
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D9D9D9', // Ensure this matches your border style
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B0B0B0', // Change border color on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7F849B', // Change border color when focused
              },
            }}
          >
            {/* Map assessmentDefinitions to options */}
            {assessmentDefinitions.map((assessment) => (
              <MenuItem key={assessment.id} value={assessment.id}>
                {assessment.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item md={3}
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'end'
          }}>
          <DaniaButton
            onClick={handleDelete}
            sx={{
              color: '#7F849B',
            }}
          >
            Reset Dashboard Layout
          </DaniaButton>
        </Grid>
      </Grid>


      {!dataFetching && dashboardConfigCopy && selectedAssessmentDefinition ? (
        <Grid item md={12}>
          <GridStack
            branding={branding}
            loggedInUser={loggedInUser}
            dashboardConfiguration={dashboardConfigCopy}
            selectedAssessmentDefinition={selectedAssessmentDefinition}
            setDashboardConfigCopy={setDashboardConfigCopy}
          />
        </Grid>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" minWidth="100%">
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};

export default AccountExecutiveDashboard;
